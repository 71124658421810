<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h5 class="card-label">Contatos</h5>
        </div>

        <hr />
        <div class="d-flex align-items-start mt-5">
          <div class="d-flex flex-column">
            <MultiSelect
              v-model="contasSelecioandas"
              :options="contas"
              style="width: 300px"
              class="mr-3"
              :class="{
                'p-invalid': !contasSelecioandas.length && contasInvalidas
              }"
              optionValue="id"
              optionLabel="label"
              placeholder="Selecione a conta"
              :filter="true"
            >
              <template #option="slotProps">
                <div class="country-item">
                  <div>
                    {{ slotProps.option.id }} -
                    {{ slotProps.option.login }}
                    {{
                      slotProps.option.nome ? `(${slotProps.option.nome})` : ""
                    }}
                  </div>
                </div>
              </template>
            </MultiSelect>
            <small
              class="p-invalid"
              v-if="!contasSelecioandas.length && contasInvalidas"
              >Selecione uma conta, caso não tenha nenhum telefone</small
            >
          </div>
          <span class="p-float-label mr-2">
            <InputText
              id="telefone"
              type="text"
              v-model="telefone"
              @keyup.enter="fetchData"
              aria-describedby="username2-help"
              placeholder="Informe o numero exato de telefone"
              :class="{ 'p-invalid': !telefone && telefoneInvalido }"
            />
          </span>
          <Dropdown
            class="mr-2"
            v-model="status"
            :options="optionsStatus"
            style="width: 200px"
            optionLabel="name"
            optionValue="value"
            placeholder="Selecione um status"
          />

          <Button
            label="Filtrar"
            @click="fetchData"
            :disabled="isLoading"
            style="color: white"
          />
        </div>
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :busy="isLoading"
          responsive="sm"
          empty-text="Nenhum registro encontrado"
          @sort-changed="sortChanged"
        >
          <template #cell(conta_id)="row">
            {{ contas.find((c) => c.id === row.value).login }} ({{ row.value }})
          </template>

          <template #cell(msg_data_cadastro)="row">
            {{ formatarData(row.value) }}
          </template>

          <template #cell(cco_status)="row">
            <div class="d-flex align-items-center">
              <div
                v-if="row.value == 'S'"
                style="
                  background-color: #c9f7f5;
                  width: 30px;
                  border-radius: 5px;
                "
                class="d-flex justify-content-center align-items-center p-2 mr-1"
                v-b-tooltip.noninteractive
                title="Desbloqueado"
              >
                <i style="color: #1bc5bd" class="fas fa-lock-open"></i>
              </div>
              <div
                v-else
                style="
                  background-color: #ffe2e5;
                  width: 30px;
                  border-radius: 5px;
                "
                class="d-flex justify-content-center align-items-center p-2 mr-1"
                v-b-tooltip.noninteractive
                title="Bloqueado"
              >
                <i style="color: #f64e60" class="fas fa-lock"></i>
              </div>
              <div
                :id="`icone-callback-${row.item.mensagem_id}`"
                v-if="row.item.callback"
                style="
                  background-color: #bbe3f9;
                  width: 30px;
                  height: 30px;
                  border-radius: 5px;
                "
                class="d-flex justify-content-center align-items-center p-2 pt-3 mr-1"
              >
                <i style="color: #4e97f6" class="flaticon2-reply"></i>
              </div>
              <b-tooltip :target="`icone-callback-${row.item.mensagem_id}`">
                <div>
                  <div class="d-flex">
                    <i class="fa fa-comment-alt mr-3"></i>
                    <p
                      style="
                        text-align: left;
                        font-size: 0.8rem;
                        word-wrap: break-word;
                        word-break: break-word;
                        white-space: normal;
                        max-width: 300px;
                        overflow-wrap: break-word;
                      "
                    >
                      {{ row.item.msg_conteudo }}
                    </p>
                  </div>
                  <div class="d-flex">
                    <i class="flaticon2-reply mr-3"></i>
                    <p
                      style="
                        text-align: left;
                        font-size: 0.8rem;
                        word-wrap: break-word;
                        word-break: break-word;
                        white-space: normal;
                        max-width: 300px;
                        overflow-wrap: break-word;
                      "
                    >
                      {{ row.item.callback }}
                    </p>
                  </div>
                </div>
              </b-tooltip>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner small type="grow"></b-spinner>
              <span class="ml-2">Carregando...</span>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-2">
              <b-icon icon="exclamation-circle" variant="danger" size="2rem" />
              <p>Nenhum registro encontrado</p>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-item
                v-if="row.item.cco_status == 'N'"
                @click="ativarConta(row.item)"
                href="#"
                >Desbloquear</b-dropdown-item
              >
              <b-dropdown-item v-else @click="bloquearConta(row.item)" href="#"
                >Bloquear</b-dropdown-item
              >
              <b-dropdown-item
                @click="limparCallback(row.item)"
                v-if="row.item.callback"
                href="#"
                >Limpar callback</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>

        <div v-if="!items.length">
          <b-alert show variant="info"> Nenhum registro encontrado </b-alert>
        </div>

        <div class="row" v-if="totalRows > 0">
          <div class="col-md-4">
            <b-form-group>
              <b-form-select
                :options="optionsPerPage"
                v-model="perPage"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-8">
            <b-pagination
              :total-rows="totalRows"
              v-model="currentPage"
              :per-page="perPage"
              align="fill"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import moment from "moment-timezone";

export default {
  name: "Contatos",
  components: { MultiSelect, InputText, Dropdown, Button },
  data() {
    return {
      items: [],
      fields: [
        { key: "conta_id", label: "Conta" },
        { key: "cco_telefone", label: "Telefone" },
        { key: "msg_data_cadastro", label: "Data mensagem", sortable: true },
        { key: "cco_status", label: "Status", sortable: true },
        { key: "actions", label: "Ação" }
      ],
      totalRows: 0,
      optionsPerPage: [
        {
          value: 10,
          text: "Exibir 10 registros por página"
        },
        {
          value: 20,
          text: "Exibir 20 registros por página"
        },
        {
          value: 50,
          text: "Exibir 50 registros por página"
        },
        {
          value: 100,
          text: "Exibir 100 registros por página"
        }
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: "",
      orderDirection: false,
      status: "",
      optionsStatus: [
        { name: "Todos", value: null },
        { name: "Desbloqueado", value: "S" },
        { name: "Bloqueado", value: "N" }
      ],
      telefone: "",
      isLoading: false,
      contas: [],
      contasSelecioandas: [],
      telefoneInvalido: false,
      contasInvalidas: false
    };
  },
  computed: {
    ...mapGetters(["contasParaTroca"])
  },
  methods: {
    async fetchData(ctx) {
      if (!this.contasSelecioandas.length && !this.telefone) {
        this.contasInvalidas = true;
        return;
      }
      if (!this.telefone && !this.contasSelecioandas.length) {
        this.telefoneInvalido = true;
        return;
      }
      this.telefoneInvalido = this.contasInvalidas = false;

      this.isLoading = true;
      try {
        const response = await ApiService.get("/conta-contato", null, true, {
          params: {
            page: this.currentPage,
            per_page: this.perPage,
            telefone: this.telefone,
            cco_status: this.status,
            conta_id: this.contasSelecioandas,
            sort_by: this.sortBy,
            sort_direction: this.orderDirection
          }
        });

        this.items = response.data.data;
        this.totalRows = response.data.total_records;
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async bloquearConta(conta) {
      try {
        Swal.fire({
          title: "Você tem certeza?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ffa800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, bloquear contato!",
          cancelButtonText: "Cancelar"
        }).then(async (result) => {
          if (result.isConfirmed) {
            await ApiService.post(
              `/conta-contato/${conta.conta_id}/${conta.cco_telefone}/status`,
              { status: "N" }
            );
            this.fetchData();
          }
        });
      } catch (error) {
        console.error("Erro ao ativar conta:", error);
      }
    },
    async ativarConta(conta) {
      try {
        Swal.fire({
          title: "Você tem certeza?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ffa800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, desbloquear contato!",
          cancelButtonText: "Cancelar"
        }).then(async (result) => {
          if (result.isConfirmed) {
            await ApiService.post(
              `/conta-contato/${conta.conta_id}/${conta.cco_telefone}/status`,
              { status: "S" }
            );
            this.fetchData();
          }
        });
      } catch (error) {
        console.error("Erro ao ativar conta:", error);
      }
    },
    async limparCallback(conta) {
      try {
        Swal.fire({
          title: "Você tem certeza?",
          text: "Você não poderá reverter esta ação!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ffa800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, limpar callback!",
          cancelButtonText: "Cancelar"
        }).then(async (result) => {
          if (result.isConfirmed) {
            await ApiService.post(
              `/conta-contato/${conta.mensagem_id}/limpar-callback`
            );
            this.fetchData();
          }
        });
      } catch (error) {
        console.error("Erro ao limpar callback:", error);
      }
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderDirection = e.sortDesc ? "desc" : "asc";
      this.fetchData();
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contatos" }]);
    this.contas = this.contasParaTroca.map((conta) => ({
      ...conta,
      label: `${conta.id} - ${conta.login} ${
        conta.nome ? "(" + conta.nome + ")" : ""
      }`
    }));
  },
  watch: {
    currentPage: "fetchData",
    perPage: "fetchData"
  }
};
</script>

<style lang="scss" scoped></style>
